vue
<template>
  <div class="export-model">
    <el-dialog :visible.sync="dialogVisible" class="dialog">
      <div slot="title" class="export_modal_top ">
        <span>{{ "记录本原始数据" }}</span>
        <span @click="dialogVisible = false">{{ "×" }}</span>
      </div>
      <div class="box">
        <div class="info-content">
          <div v-for="(item, index) of machInfos" :key="index" class="info-item">
            <div class="label">
              <span>{{ item.label }}</span>
            </div>
            <div class="content">
              {{ machInfo[item.key] }}
            </div>
          </div>
        </div>
      </div>
      <div class="button-box">
        <button class="btn-normal primary-button" @click="view">
          {{ "查看原始数据" }}
        </button>
        <button class="btn-normal primary-button" @click="downLoadFile_">
          {{ "下载原始数据" }}
        </button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Message } from "element-ui"
import { getDetails } from "@/api/order";
import { download } from "@/utils/common";
import { downloadFileByrecordDir } from "@/api/data"
const machineInfoKey = {
  businessNo: "业务编号",
  client: "委托单位",
  factoryNo: "出厂编号",
  department: "部门/子公司",
  instrumentName: "仪器名称",
  testingItems: "检测项目",
  specificationsType: "服务类型",
  workTime: "受理日期",
  testingRoom: "检测场所",
  inspectionTime: "送检时间"
};
export default {
  data() {
    return {
      dialogVisible: true, // 对话框可见性
      machInfo: {}
    }
  },
  props: {
    destroy: {
      type: Function,
    },
    id: {},
    recordDir: {}
  },
  computed: {
    machInfos() {
      let items = [];
      let labelKeys = Object.keys(machineInfoKey);
      for (let key in this.machInfo) {
        if (labelKeys.includes(key)) {
          items.push({
            key: key,
            label: machineInfoKey[key],
            value: this.machInfo[key],
          });
        }

      }
      return items;
    },
  },
  methods: {
    getInfo: function () {
      if (this.id) {
        getDetails({ id: this.id }).then((res) => {
          this.machInfo = res.data;
        });
      }

    },
    downLoadFile_: async function () {
      try {
        let res = await downloadFileByrecordDir({ recordDir: this.recordDir });
        download(this.recordDir, res.data);
      } catch (error) {

      }
    },
    view: function () {
      POBrowser.openWindow(
        "/PgOffice/viewOldData",
        "width=980px;height=800px;",
        JSON.stringify({ recordDir: this.recordDir })
      );
    }
  },
  created() {
    console.log(this.id);
    this.getInfo();
  },
};
</script>
<style lang="less" scope>
.export_modal_top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span:last-child {
    cursor: pointer;
    font-size: 18px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}

.el-dialog__body {
  padding: 0 20px;
}

.dialog {
  .el-dialog {
    width: fit-content;
  }

  .el-dialog__headerbtn {
    display: none;
  }
}

.dialog-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
}

.info-content {
  height: 100%;
  width: 100%;
  border-top: 1px solid #bbbbbb;
  border-left: 1px solid #bbbbbb;
  display: flex;
  flex-flow: column wrap;
  display: grid;
  grid-template-columns: 50% 50%;

  .info-item {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #bbbbbb;
    border-right: 1px solid #bbbbbb;

    .label {
      border-right: 1px solid #bbbbbb;
      height: 100%;
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--fontSizeBig);
      font-weight: bold;
    }

    .content {
      width: 70%;
      height: 100%;
      padding: 1%;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-input {
        font-size: var(--fontSizeBig);
      }
    }
  }
}

.box {
  height: 500px;
  width: 900px;
  color: #000;

}

.button-box {
  display: flex;
  padding: 20px;
  justify-content: space-around;
}
</style>