import modal from "./viewModel/index";
import { downloadWorld } from "@/api/data";
import { download } from "@/utils/common";
export default (context) => {
  return {
    columns: [
      {
        prop: "businessNo",
        label: "业务编号",
        width: 10,
      },
      {
        prop: "instrumentName",
        label: "仪器名称",
        width: 15,
      },
      {
        prop: "factoryNo",
        label: "出厂编号",
        width: 10,
      },
      {
        prop: "specificationsType",
        label: "型号规格",
        width: 10,
      },
      {
        prop: "client",
        label: "委托单位",
        width: 20,
      },
      {
        prop: "workTime",
        label: "完成日期",
        width: 10,
      },
    ],
    operate: [
      {
        class: "text-button", //操作按钮显示类型
        label: "查看报告", //按钮显示文字
        key: "look",
        handle: (e) => {
          POBrowser.openWindow(
            "/PgOffice/simpleWord",
            "width=980px;height=800px;",
            JSON.stringify({
              fileDir: decodeURIComponent(e.wordDir),
              orderId: e.id,
            })
          );
          window.PageOfficeCbFn = () => {};
          window.PageOfficeCloseFn = () => {};
          // context.$router.push({ name: "completedOrderView", params: { id: e.id, pdfName: e.pdfName } })
        },
      },
      {
        class: "text-button", //操作按钮显示类型
        label: "查看记录本数据", //按钮显示文字
        key: "look",
        handle: (e) => {
          modal({
            id: e.id,
            recordDir: e.recordDir,
          });
        },
      },
      {
        class: "text-button", //操作按钮显示类型
        label: "导出", //按钮显示文字
        key: "look",
        handle: async (e) => {
          try {
            const { data } = await downloadWorld({
              fileName: e.wordName,
              orderId: e.id,
            });
            download(e.wordName, data);
          } catch (error) {
            console.log(error);
          }
        },
      },
    ],
    operateWith: 20,
  };
};
