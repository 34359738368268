import request from "@/utils/request";
import downloadRequest from "@/utils/downloadRequest";

/**
 * 导入文件
 * @param {*} file
 * @returns
 */
export const importFile = (file) => {
  return request({
    url: "/workOrder/importData",
    method: "post",
    data: file,
  });
};

/**
 * 获取导出文件件列表
 * @param {} params
 * @returns
 */
export const getExportFileList = (params) => {
  return request({
    url: "/SimpleFinalExcel/pdfList",
    method: "get",
    params,
  });
};

/**
 * 下载文件
 * @param {} params
 * @returns
 */
export const downloadFile = (params) => {
  return downloadRequest({
    url: "/common/download/resource",
    method: "GET",
    params,
  });
};

/**
 * 下载工单导入信息模板
 * @returns
 */
export const getOrderimportInfo = () => {
  return downloadRequest({
    url: "/workOrder/importTemplate",
    method: "get",
  });
};

/**
 * 下载用户导入信息模板
 * @returns
 */
export const getUserimportInfo = () => {
  return downloadRequest({
    url: "/system/user/importTemplate",
    method: "post",
  });
};

/**
 *
 * @param {*} params String[] files
 * @returns 批量导出
 */
export const downloadPDFZIP = function (params) {
  return downloadRequest({
    url: "/common/download/zip",
    method: "get",
    params,
  });
};

/**
 *
 * @param {*} params String fileName,Long orderId
 * @returns 单个导出
 */
export const downloadPDF = function (params) {
  return downloadRequest({
    url: "/common/download/pdf",
    method: "get",
    params,
  });
};

/**
 * 单个导出原始数据
 * @param {} params
 * @returns
 */
export const downloadFileByrecordDir = (params) => {
  return downloadRequest({
    url: "/common/download/record",
    method: "GET",
    params,
  });
};

/**
 * 批量导出原始数据
 */

export const downloadFileByrecordDirBatch = function (params) {
  return downloadRequest({
    url: "/common/download/record/zip",
    method: "GET",
    params,
  });
};

/**
 * 导出World
 */
export const downloadWorld = function (params) {
  return downloadRequest({
    url: "/common/download/word",
    method: "GET",
    params,
  });
};
