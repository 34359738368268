import Vue from "vue"
import exportModel from "./index.vue"
export default function (option) {
  const mountDom = document.createElement('div');
  mountDom.id = "completed-moddel-dom"
  document.body.appendChild(mountDom);

  // });

  const model_vue = new Vue({
    render: h => h(exportModel, { props: { destroy: destroy, ...option } }),
    strict: false
  });

  /**
   * 销毁函数
   */
  function destroy() {
    document.body.removeChild(model_vue.$el);
    model_vue.$destroy();
  }
  /**
   * 挂载到Dom上
   */
  model_vue.$mount('#completed-moddel-dom');
}