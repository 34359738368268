<template>
  <div class="completed-ordder-view-box">
    <view-card title="已完成工单">
      <div class="content" slot="content">
        <div class="table-tools-box">
          <div class="filter-item">
            <span>筛选日期</span>
            <el-date-picker v-model="timeFilter" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
          <div class="filter-item">
            <span>业务编号</span>
            <el-input v-model="businessCodeFilter" placeholder="请输入内容"></el-input>
          </div>
          <div class="filter-item">
            <span>仪器名称</span>
            <el-input v-model="detectionObjectFilter" placeholder="请输入内容"></el-input>
          </div>
          <div class="filter-item">
            <span>委托单位</span>
            <el-input v-model="clientFilter" placeholder="请输入内容"></el-input>
          </div>
          <div class="filter-item">
            <button class="search-button" @click="search">搜索</button>
          </div>
          <!-- <div class="filter-item export">
            <svg-icon icon-class="export" :size="1.5"></svg-icon>
            <button class="export-button">导出</button>
          </div> -->
        </div>
        <div class="table-content">
          <Table :option="tableConfig" :data="tableData" :isShowPagination="true" :showTotal="true" :total="dataTotal"
            @Change="dataUpdate"></Table>
        </div>
      </div>
    </view-card>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { getOrderList } from "@/api/order";
import tableConfig from "./tableConfig";
export default {
  name: "completedOrderView",
  components: {
    Table,
  },
  data() {
    return {
      timeFilter: "",
      businessCodeFilter: "",
      detectionObjectFilter: "",
      clientFilter: "",
      dataTotal: "",
      tableConfig: {},
      tableData: [],
    };
  },
  methods: {
    getOrderList: function (params) {
      getOrderList({ workStatusList: "3", ...params })
        .then((res) => {
          this.dataTotal = res.total;
          this.tableData = res.rows;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    dataUpdate(e) {
      this.getOrderList(e);
    },
    search() {
      let params = {
        startTime: (this.timeFilter && this.timeFilter[0]) || null,
        endTime: (this.timeFilter && this.timeFilter[1]) || null,
        businessNo: this.businessCodeFilter || null,
        instrumentName: this.detectionObjectFilter || null,
        client: this.clientFilter || null,
      };
      this.dataUpdate(params);
    },
  },
  created() {
    this.tableConfig = tableConfig(this);
    this.getOrderList({ pageSize: 10, pageNum: 1 });
  },
};
</script>

<style lang="less" scoped>
.completed-ordder-view-box {
  background-color: #ffffff;
  border-radius: 10px;
  height: 100%;

  .content {
    gap: 20px;
    display: flex;
    flex-flow: column;
    height: 100%;

    .table-content {
      flex: 1;
      overflow: hidden;
    }

    .export {
      gap: 0 !important;
      cursor: pointer;
      color: transparent;

      .export-button {
        border: none;
        background-color: transparent;
        color: var(--blue);
        font-size: var(--fontSizeNormal);
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
</style>
